import _ from 'lodash';
import { routes } from './routes';
import Capacitor from '../utils/Capacitor';
import AuthState from '../utils/common/auth-state';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { PaymentsPayload_CreateOrder, PaymentsPayload_Completed } from '@com.xcodeclazz/monolithic-common';
import { CompilersPayload_Cpp, CompilersPayload_Java, CompilersPayload_Python, CompilersPayload_Node } from '@com.xcodeclazz/compile-run-v2';
import { CoursesPayload_ChangeActiveCourse, CoursesPayload_CreateCourse, CoursesPayload_EnrolledCourse, CoursesPayload_ShowCourse, CoursesPayload_SubscribeCourseBypass, CoursesPayload_CourseSubscribeRequest, CoursesPayload_UpdateCourse } from '@com.xcodeclazz/monolithic-common';
import { QuestionsPayload_CreateQuestion, QuestionsPayload_DeleteQuestion, QuestionsPayload_QuestionLikeUnlike, QuestionsPayload_ShowRawQuestion, QuestionsPayload_ShowQuestionState, QuestionsPayload_ShowQuestion, QuestionsPayload_UpdateQuestion } from '@com.xcodeclazz/monolithic-common';
import { TopicsPayload_CreateTopic, TopicsPayload_DeleteEveryTopic, TopicsPayload_DeleteSingleTopic, TopicsPayload_ReorderQuestionsList, TopicsPayload_ShowTopicQuestionsList, TopicsPayload_ShowTopicStatus, TopicsPayload_ShowTopic, TopicsPayload_ShowTopics, TopicsPayload_UpdateTopic } from '@com.xcodeclazz/monolithic-common';
import { SubmissionsPayload_ExecuteSolutionCode, SubmissionsPayload_ExecuteTestsCases, SubmissionsPayload_ShowSubmissionsOfAnyone, SubmissionsPayload_ShowSubmissionsOfCoding, SubmissionsPayload_ShowSubmissionsOfFill, SubmissionsPayload_ShowSubmissionsOfMcq, SubmissionsPayload_SubmitAnyone, SubmissionsPayload_SubmitCoding, SubmissionsPayload_SubmitFill, SubmissionsPayload_SubmitMcq } from '@com.xcodeclazz/monolithic-common';

const authState = new AuthState();

const handle = (response: HttpResponse, cb: (response: HttpResponse) => void) => {
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode <= 399) return cb(response);
    else if (statusCode >= 400 && statusCode <= 499) throw { response };
    else if (statusCode >= 500 && statusCode <= 599) throw { response };
    throw { response };
};

export const postCompileCpp = _.debounce(( data: CompilersPayload_Cpp, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_CPP,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCompileJava = _.debounce(( data: CompilersPayload_Java, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_CPP,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCompileNode = _.debounce(( data: CompilersPayload_Node, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_NODE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCompilePython = _.debounce(( data: CompilersPayload_Python, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COMPILERS_PYTHON,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCourseActive = _.debounce(( data: CoursesPayload_ChangeActiveCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES_ACTIVE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCourseCreateNew = _.debounce(( data: CoursesPayload_CreateCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES_NEW,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCourseEnroll = _.debounce(( data: CoursesPayload_EnrolledCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES_ENROLL,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getCoursesEnrolled = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_COURSES_ENROLLED,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getCourseActive = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_COURSES_ACTIVE,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postShowCourse = _.debounce(( data: CoursesPayload_ShowCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getCourses = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_COURSES,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getCoursesAll = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_COURSES_ALL,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCourseSubscribeBypass = _.debounce(( data: CoursesPayload_SubscribeCourseBypass, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES_SUBSCRIBE_BYPASS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postCourseSubscribe = _.debounce(( data: CoursesPayload_CourseSubscribeRequest, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_COURSES_SUBSCRIBE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const patchCourse = _.debounce(( data: CoursesPayload_UpdateCourse, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.patch({
        url: routes.PATCH_COURSES,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postPaymentCreateOrder = _.debounce(( data: PaymentsPayload_CreateOrder, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_PAYMENTS_CREATE_ORDER,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postPaymentComplete = _.debounce(( data: PaymentsPayload_Completed, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_PAYMENTS_COMPLETED,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getPayments = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_PAYMENTS,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postQuestionCreate = _.debounce(( data: QuestionsPayload_CreateQuestion, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_QUESTIONS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const deleteQuestion = _.debounce(( data: QuestionsPayload_DeleteQuestion, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.delete({
        url: routes.DELETE_QUESTIONS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postQuestionLikeUnlike = _.debounce(( data: QuestionsPayload_QuestionLikeUnlike, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_QUESTIONS_LIKE_UNLIKE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getQuestionRaw = _.debounce(( data: QuestionsPayload_ShowRawQuestion, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_QUESTIONS_RAW_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getQuestionStage = _.debounce(( data: QuestionsPayload_ShowQuestionState, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_QUESTIONS_STATE_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getQuestion = _.debounce(( data: QuestionsPayload_ShowQuestion, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_QUESTIONS_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getQuestionsTrending = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_QUESTIONS_TRENDING,
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const patchQuestion = _.debounce(( data: QuestionsPayload_UpdateQuestion, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.patch({
        url: routes.PATCH_QUESTIONS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionExecuteSolutionCode = _.debounce(( data: SubmissionsPayload_ExecuteSolutionCode, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_EXECUTE_SOLUTION_CODE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionExecuteTestsCases = _.debounce(( data: SubmissionsPayload_ExecuteTestsCases, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_EXECUTE_TESTS_CASES,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getSubmissionsAnyone = _.debounce(( data: SubmissionsPayload_ShowSubmissionsOfAnyone, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SUBMISSIONS_ANYONE_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getSubmissionsCoding = _.debounce(( data: SubmissionsPayload_ShowSubmissionsOfCoding, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SUBMISSIONS_CODING_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getSubmissionsFill = _.debounce(( data: SubmissionsPayload_ShowSubmissionsOfFill, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SUBMISSIONS_FILL_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getSubmissionsMcq = _.debounce(( data: SubmissionsPayload_ShowSubmissionsOfMcq, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_SUBMISSIONS_MCQ_QID.replace(':qid', data.qid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionAnyone = _.debounce(( data: SubmissionsPayload_SubmitAnyone, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_ANYONE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionCoding = _.debounce(( data: SubmissionsPayload_SubmitCoding, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_CODING,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionFill = _.debounce(( data: SubmissionsPayload_SubmitFill, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_FILL,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postSubmissionMcq = _.debounce(( data: SubmissionsPayload_SubmitMcq, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_SUBMISSIONS_MCQ,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postTopicCreate = _.debounce(( data: TopicsPayload_CreateTopic, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_TOPICS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const deleteTopicEveryDelete = _.debounce(( data: TopicsPayload_DeleteEveryTopic, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.delete({
        url: routes.DELETE_TOPICS_EVERY,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const deleteTopicSingleDelete = _.debounce(( data: TopicsPayload_DeleteSingleTopic, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.delete({
        url: routes.DELETE_TOPICS_SINGLE,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const postTopicQuestionsListReorder = _.debounce(( data: TopicsPayload_ReorderQuestionsList, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.post({
        url: routes.POST_TOPICS_QUESTIONS_LIST_REORDER,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getTopicQuestionsList = _.debounce(( data: TopicsPayload_ShowTopicQuestionsList, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_TOPICS_QUESTIONS_LIST_CID_TID.replace(':cid', data.cid.toString()).replace(':tid', data.tid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getTopicStatus = _.debounce(( data: TopicsPayload_ShowTopicStatus, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_TOPICS_STATUS_CID_TID.replace(':cid', data.cid.toString()).replace(':tid', data.tid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getTopic = _.debounce(( data: TopicsPayload_ShowTopic, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_TOPICS_CID_TID.replace(':cid', data.cid.toString()).replace(':tid', data.tid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getTopics = _.debounce(( data: TopicsPayload_ShowTopics, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: routes.GET_TOPICS_CID.replace(':cid', data.cid.toString()),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const patchTopic = _.debounce(( data: TopicsPayload_UpdateTopic, cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.patch({
        url: routes.PATCH_TOPICS,
        data: JSON.stringify(data),
        headers: commonHeader(),
    }).then(e => handle(e, cb)).catch(err);
});

export const getRandomProgrammingJoke = _.debounce(( cb: (response: HttpResponse) => void, err: (e: any) => void) => {
    CapacitorHttp.get({
        url: "https://official-joke-api.appspot.com/jokes/programming/random",
    }).then(e => handle(e, cb)).catch(err);
});

export const errorToast = _.debounce((error: any) => {
    // @ts-ignore
    const message = _.first(error.response?.data.errors)?.message;
    if (message) Capacitor.toast(message, "long");
});

const commonHeader = () => {
    return {
        'Content-Type': 'application/json',
        'base64': authState.getToken() || '',
    };
}