const backend = process.env.REACT_APP_SERVER_BACKEND_URL || ''; // proxy
const compilers = process.env.REACT_APP_SERVER_COMPILERS_URL || 'http://localhost:5000/';
const monolithic = process.env.REACT_APP_SERVER_MONOLITHIC_URL || 'http://localhost:8087/';

export const routes = {
  POST_COMPILERS_CPP: `${compilers}api/compilers/cpp`,
  POST_COMPILERS_JAVA: `${compilers}api/compilers/java`,
  POST_COMPILERS_NODE: `${compilers}api/compilers/node`,
  POST_COMPILERS_PYTHON: `${compilers}api/compilers/python`,

  GET_COURSES: `${monolithic}api/courses`,
  POST_COURSES: `${monolithic}api/courses`,
  PATCH_COURSES: `${monolithic}api/courses`,
  GET_COURSES_ALL: `${monolithic}api/courses/all`,
  POST_COURSES_NEW: `${monolithic}api/courses/new`,

  GET_COURSES_ACTIVE: `${monolithic}api/courses/active`,
  POST_COURSES_ACTIVE: `${monolithic}api/courses/active`,
  POST_COURSES_ENROLL: `${monolithic}api/courses/enroll`,
  GET_COURSES_ENROLLED: `${monolithic}api/courses/enrolled`,
  POST_COURSES_SUBSCRIBE: `${monolithic}api/courses/subscribe`,
  POST_COURSES_SUBSCRIBE_BYPASS: `${monolithic}api/courses/subscribe/bypass`,

  POST_PAYMENTS_CREATE_ORDER: `${monolithic}api/payments/create-order`,
  POST_PAYMENTS_COMPLETED: `${monolithic}api/payments/completed`,
  GET_PAYMENTS: `${monolithic}api/payments`,

  POST_QUESTIONS: `${monolithic}api/questions`,
  PATCH_QUESTIONS: `${monolithic}api/questions`,
  DELETE_QUESTIONS: `${monolithic}api/questions`,
  GET_QUESTIONS_RAW_QID: `${monolithic}api/questions/raw/:qid`,
  GET_QUESTIONS_TRENDING: `${monolithic}api/questions/trending`,

  GET_QUESTIONS_QID: `${monolithic}api/questions/:qid`,
  GET_QUESTIONS_STATE_QID: `${monolithic}api/questions/state/:qid`,
  POST_QUESTIONS_LIKE_UNLIKE: `${monolithic}api/questions/like-unlike`,

  POST_SUBMISSIONS_EXECUTE_SOLUTION_CODE: `${monolithic}api/submissions/execute-solution-code`,
  POST_SUBMISSIONS_EXECUTE_TESTS_CASES: `${monolithic}api/submissions/execute-tests-cases`,
  GET_SUBMISSIONS_ANYONE_QID: `${monolithic}api/submissions/anyone/:qid`,
  GET_SUBMISSIONS_CODING_QID: `${monolithic}api/submissions/coding/:qid`,
  GET_SUBMISSIONS_FILL_QID: `${monolithic}api/submissions/fill/:qid`,
  GET_SUBMISSIONS_MCQ_QID: `${monolithic}api/submissions/mcq/:qid`,
  POST_SUBMISSIONS_ANYONE: `${monolithic}api/submissions/anyone`,
  POST_SUBMISSIONS_CODING: `${monolithic}api/submissions/coding`,
  POST_SUBMISSIONS_FILL: `${monolithic}api/submissions/fill`,
  POST_SUBMISSIONS_MCQ: `${monolithic}api/submissions/mcq`,

  POST_TOPICS: `${monolithic}api/topics`,
  PATCH_TOPICS: `${monolithic}api/topics`,
  GET_TOPICS_CID: `${monolithic}api/topics/:cid`,
  DELETE_TOPICS_EVERY: `${monolithic}api/topics/every`,
  DELETE_TOPICS_SINGLE: `${monolithic}api/topics/single`,
  GET_TOPICS_CID_TID: `${monolithic}api/topics/:cid/:tid`,
  POST_TOPICS_QUESTIONS_LIST_REORDER: `${monolithic}api/topics/questions/list/reorder`,
  GET_TOPICS_QUESTIONS_LIST_CID_TID: `${monolithic}api/topics/questions/list/:cid/:tid`,
  GET_TOPICS_STATUS_CID_TID: `${monolithic}api/topics/status/:cid/:tid`,
};