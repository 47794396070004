import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { CoursesResponse_ShowCourses } from "@com.xcodeclazz/monolithic-common";

export const courseState = createSlice({
  name: "courseState",
  initialState: {
    courses: { courses: [] } as CoursesResponse_ShowCourses,
  },
  reducers: {
    saveCourses: (state, action) => {
      if (action.payload?.courses) state.courses = action.payload;
    },
    insertCourse: (state, action) => {
      if (action.payload?.course) state.courses.courses.unshift(action.payload.course);
    },
    clearCoursesState: (state) => {
      state.courses = { courses: [] };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveCourses, clearCoursesState, insertCourse } = courseState.actions;

export default courseState.reducer;
