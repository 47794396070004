import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { TopicsResponse_ShowTopics } from "@com.xcodeclazz/monolithic-common/build/responses/topics";

export const topicState = createSlice({
  name: "topicState",
  initialState: {
    courseTopics: {} as { [cid: string]: TopicsResponse_ShowTopics },
  },
  reducers: {
    saveCourseTopics: (state, action) => {
      if (action.payload?.topic)
        state.courseTopics[action.payload?.topic?.course] = action.payload;
    },
    clearTopicState: (state) => {
      state.courseTopics = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearTopicState, saveCourseTopics } = topicState.actions;

export default topicState.reducer;
