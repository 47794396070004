import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
  ITopic,
  QuestionsResponse_CreateQuestion,
  QuestionsResponse_ShowTrendingQuestionsList,
} from "@com.xcodeclazz/monolithic-common";

export const questionState = createSlice({
  name: "questionState",
  initialState: {
    topicDetails: undefined as ITopic | undefined,
    trending: undefined as
      | QuestionsResponse_ShowTrendingQuestionsList
      | undefined,
  },
  reducers: {
    saveTopicDetails: (state, action) => {
      action.payload.topic.questions.reverse();
      state.topicDetails = action.payload.topic;
    },
    saveTrendingQuestions: (state, action) => {
      state.trending = action.payload;
    },
    insertQuestion: (state, action) => {
      const response: QuestionsResponse_CreateQuestion = action.payload;
      state.topicDetails?.questions.unshift({
        index: state.topicDetails?.questions?.length,
        question: response.question,
      });
    },
    clearQuestionState: (state) => {
      state.topicDetails = undefined;
      state.trending = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  insertQuestion,
  saveTopicDetails,
  clearQuestionState,
  saveTrendingQuestions,
} = questionState.actions;

export default questionState.reducer;
