import _ from "lodash";
import Joi from "joi";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import { errorToast, postCourseCreateNew } from "../apis";
import { insertCourse } from "../redux/reducers/courseState";
import { Currency } from "@com.xcodeclazz/monolithic-common/build/constants/courses";
import { CoursesPayloadJoi_CreateCourse, CoursesPayload_CreateCourse } from "@com.xcodeclazz/monolithic-common/build/payloads/courses";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonPage,
  IonCard,
  IonInput,
  IonSelect,
  IonContent,
  IonTextarea,
  IonRippleEffect,
  IonSelectOption,
} from "@ionic/react";

const CreateCourse: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [thumbnailSrc, setThumbnailSrc] = useState<string>("https://via.placeholder.com/500x300");

  const previewVideoRef = React.createRef<HTMLIonInputElement>();
  const long_descriptionRef = React.createRef<HTMLIonTextareaElement>();
  const brief_descriptionRef = React.createRef<HTMLIonTextareaElement>();

  const featuresRef = React.createRef<HTMLIonInputElement>();
  const keywordsRef = React.createRef<HTMLIonInputElement>();

  const titleRef = React.createRef<HTMLIonInputElement>();
  const priceRef = React.createRef<HTMLIonInputElement>();
  const discountRef = React.createRef<HTMLIonInputElement>();
  const subtitleRef = React.createRef<HTMLIonInputElement>();
  const currencyRef = React.createRef<HTMLIonSelectElement>();
  const hasActiveRef = React.createRef<HTMLIonInputElement>();

  const duration_unitRef = React.createRef<HTMLIonInputElement>();
  const duration_valueRef = React.createRef<HTMLIonInputElement>();
  const duration_titleRef = React.createRef<HTMLIonInputElement>();

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateCourse = () => {
    const previewVideo = previewVideoRef.current?.value?.toString();
    const long_description = long_descriptionRef.current?.value?.toString();
    const brief_description = brief_descriptionRef.current?.value?.toString();
    const features = featuresRef.current?.value?.toString();
    const keywords = keywordsRef.current?.value?.toString();
    const title = titleRef.current?.value?.toString();
    const price = priceRef.current?.value?.toString();
    const discount = discountRef.current?.value?.toString();
    const subtitle = subtitleRef.current?.value?.toString();
    const currency = currencyRef.current?.value?.toString();
    const hasActive = hasActiveRef.current?.value?.toString();

    const duration_unit = duration_unitRef.current?.value?.toString();
    const duration_value = duration_valueRef.current?.value?.toString();
    const duration_title = duration_titleRef.current?.value?.toString();

    if (previewVideo == "" || long_description == "" || brief_description == "" || features == "" || keywords == "" || title == "" || price == "" || discount == "" || subtitle == "" || currency == "" || duration_unit == "" || duration_value == "" || duration_title == "") return;

    const data: CoursesPayload_CreateCourse = {
      price: +(price || 0),
      discount: +(discount || 0),
      thumbnail: thumbnailSrc,
      keywords: (keywords || "").split(",").map((e) => e.trim()),
      features: (features || "").split(",").map((e) => e.trim()),
      hasActive: hasActive?.toString() == "true" ? true : false,

      currency,
      title: title || "",
      subtitle: subtitle || "",
      previewVideo: previewVideo || "",
      long_description: long_description || "",
      brief_description: brief_description || "",
      duration: {
        unit: duration_unit || "",
        title: duration_title || "",
        value: +(duration_value || 0),
      },
    };
    
    const { error, value } = Joi.object(CoursesPayloadJoi_CreateCourse).validate(data);
    if (!error) {
      setLoading(true);
      postCourseCreateNew(value, (res) => {
        dispatch(insertCourse(res.data));
        if (previewVideoRef.current) previewVideoRef.current.value = "";
        if (long_descriptionRef.current) long_descriptionRef.current.value = "";
        if (brief_descriptionRef.current) brief_descriptionRef.current.value = "";
        if (featuresRef.current) featuresRef.current.value = "";
        if (keywordsRef.current) keywordsRef.current.value = "";
        if (titleRef.current) titleRef.current.value = "";
        if (priceRef.current) priceRef.current.value = "";
        if (discountRef.current) discountRef.current.value = "";
        if (subtitleRef.current) subtitleRef.current.value = "";
        if (currencyRef.current) currencyRef.current.value = "";
        if (hasActiveRef.current) hasActiveRef.current.value = "";
        if (duration_unitRef.current) duration_unitRef.current.value = "";
        if (duration_valueRef.current) duration_valueRef.current.value = "";
        if (duration_titleRef.current) duration_titleRef.current.value = "";
        setLoading(false);
        history.goBack();
      }, errorToast);
    } else {
      Capacitor.toast(error.message, 'long');
      setLoading(false);
    }
  };

  return (
    <IonPage>
      <Header onCreate={onCreateCourse} loading={loading} />
      <IonContent fullscreen>
        <IonGrid className="h-full">
          <IonRow className="h-full">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput value={thumbnailSrc} label="Thumbnail" labelPlacement="floating" fill="outline" placeholder="https://example.com?lang30px.png" onIonChange={e => setThumbnailSrc(e.target.value?.toString() || thumbnailSrc)} />
                  </IonCol>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput ref={previewVideoRef} label="Preview Video" labelPlacement="floating" fill="outline" placeholder="https://example.com" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonTextarea ref={brief_descriptionRef} label="Brief Description" rows={4} labelPlacement="floating" fill="outline" />
                  </IonCol>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonTextarea ref={long_descriptionRef} label="Long Description" rows={4} labelPlacement="floating" fill="outline" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput ref={titleRef} label="Title" labelPlacement="floating" fill="outline" />
                  </IonCol>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput ref={subtitleRef} label="Subtitle" labelPlacement="floating" fill="outline" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect ref={currencyRef} labelPlacement="floating" fill="outline" placeholder="Currency" value={Currency.INR}>
                      {_.map(Currency, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={priceRef} type="number" label="Price" labelPlacement="floating" fill="outline" placeholder="5999" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={discountRef} type="number" label="Discount" labelPlacement="floating" fill="outline" placeholder="0" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={featuresRef} label="Features" labelPlacement="floating" fill="outline" placeholder="Feature One, Feature Two" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={keywordsRef} label="Keywords" labelPlacement="floating" fill="outline" placeholder="Key, Word" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={hasActiveRef} label="Active" labelPlacement="floating" fill="outline" placeholder="false" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={duration_titleRef} label="Title" labelPlacement="floating" fill="outline" placeholder="6 Months" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={duration_valueRef} label="Value" labelPlacement="floating" fill="outline" type="number" placeholder="6" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={duration_unitRef} label="Unit" labelPlacement="floating" fill="outline" placeholder="Months" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonGrid className="w-full">
                <IonRow className="justify-center">
                  <IonCard className="ion-activatable overflow-hidden">
                    <IonRippleEffect></IonRippleEffect>
                    <img src={thumbnailSrc} onError={e => setThumbnailSrc("https://via.placeholder.com/500x300")} className="h-full w-full" alt="thumbnail preview" />
                  </IonCard>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateCourse;
