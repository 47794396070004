import _ from "lodash";
import Joi from "joi";
import AceEditor from "react-ace";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { errorToast, postQuestionCreate } from "../apis";
import { insertQuestion } from "../redux/reducers/questionState";
import { getEditorTheme, isValidObjectId } from "../utils/common/helper";
import { IonContent, IonFooter, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { ILanguage, IQuestionDifficulty, IQuestionType } from '@com.xcodeclazz/monolithic-common/build/constants/questions';
import { QuestionsPayloadJoi_CreateQuestion, QuestionsPayload_CreateQuestion } from "@com.xcodeclazz/monolithic-common/build/payloads/questions";

const tabSpace = 2;
const CreateFillQuestion: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [fontSize] = useState<number>(18);
  const [showGutter] = useState<boolean>(true);
  const aceEditorRef = React.createRef<AceEditor>();
  const theme_state: string = useSelector((state: any) => state.uiState.theme);

  const cid = new URLSearchParams(location.search)?.get('cid');
  const tid = new URLSearchParams(location.search)?.get('tid');

  useEffect(() => {
    if (!cid || !tid) {}
    else if (!isValidObjectId(cid) || !isValidObjectId(tid)) {}
  }, []);

  const [error, setError] = useState<Joi.ValidationError | undefined>();
  const [code, setCode] = useState<string>(JSON.stringify({
    body: {
      text: '',
      code: '',
    },
    difficulty: IQuestionDifficulty.Easy,
    type: IQuestionType.Fill,
    language: ILanguage.Java,
    attempAllow: 0,
    isActive: true,
    panelty: 0,
    points: 0,
    course: cid,
    topic: tid,
    title: '',
    fill: {
      solution: [[]],
      source: '', // todo: make the parser and commit on npm
    },
  } as QuestionsPayload_CreateQuestion, null, tabSpace));

  const updateCode = (code: string, event: any) => {
    try { JSON.parse(code); } catch (err) { }
    try {
      const { error } = Joi.object(QuestionsPayloadJoi_CreateQuestion).validate(JSON.parse(code));
      setError(error);
      setCode(code);
    }
    catch (err) {}
  };

  const onCreateQuestion = () => {
    try { JSON.parse(code); } catch (err) { }
    const { error, value } = Joi.object(QuestionsPayloadJoi_CreateQuestion).validate(JSON.parse(code));
    setError(error);
    if (!error) {
      postQuestionCreate(value, res => {
        dispatch(insertQuestion(res.data));
        history.goBack();
      }, errorToast);
    } else Capacitor.toast(error.message, 'long');
  };

  return (
    <IonPage>
      <Header onCreate={onCreateQuestion} />
      <IonContent fullscreen>
        <AceEditor
          ref={aceEditorRef}
          name={`ID-${Math.random()}`}
          mode={'json5'}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={false}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => { editor.renderer.setPadding(15) }}
          style={{ height: "100%", backgroundColor: "transparent" }}
          value={code}
          // debounceChangePeriod={1000}
          onChange={updateCode}
          setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              showLineNumbers: true,
              tabSize: tabSpace,
          }}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>{error?.message}</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default CreateFillQuestion;
