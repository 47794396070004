import _ from "lodash";
import React, { useEffect } from 'react';
import Header from "../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isValidObjectId } from "../utils/common/helper";
import { errorToast, getTopicQuestionsList } from "../apis";
import { saveTopicDetails } from "../redux/reducers/questionState";
import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react';
import { ITopic } from "@com.xcodeclazz/monolithic-common/build/constants/topics";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common/build/models/question";
import { IQuestionType } from "@com.xcodeclazz/monolithic-common/build/constants/questions";

const Topic: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const cid = new URLSearchParams(location.search)?.get('cid');
  const tid = new URLSearchParams(location.search)?.get('tid');
  const topic_detail_state: ITopic | undefined = useSelector((state: any) => state.questionState.topicDetails);

  const getCourseTopic = (cid: string, tid: string) => getTopicQuestionsList({ cid, tid }, res => dispatch(saveTopicDetails(res.data)), errorToast);

  useEffect(() => {
    if (!cid || !tid) {}
    else if (!isValidObjectId(cid) || !isValidObjectId(tid)) {}
    else getCourseTopic(cid, tid);
  }, []);

  return (
    <IonPage>
      <Header
        createMcq={`/create-mcq-question?cid=${cid}&tid=${tid}`}
        // createFill={`/create-fill-question?cid=${cid}&tid=${tid}`}
        createAnyone={`/create-anyone-question?cid=${cid}&tid=${tid}`}
        createCoding={`/create-coding-question?cid=${cid}&tid=${tid}`}
      />
      <IonContent fullscreen className="ion-padding">
        <h1 className="font-bold">{topic_detail_state?.title}</h1>
        <strong>{topic_detail_state?.subtitle}</strong>
        <ul>{_.map(topic_detail_state?.questions, (el, idx) => (
          <li key={idx}>
            {(el?.question as QuestionAttrs)?.title}&nbsp;[{(el?.question as QuestionAttrs)?.type}]
            {(el?.question as QuestionAttrs).type == IQuestionType.Mcq && <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/update-mcq-question?&qid=${(el?.question as QuestionAttrs)?.id}`}>Update</IonButton>}
            {(el?.question as QuestionAttrs).type == IQuestionType.Anyone && <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/update-anyone-question?&qid=${(el?.question as QuestionAttrs)?.id}`}>Update</IonButton>}
            {(el?.question as QuestionAttrs).type == IQuestionType.Coding && <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/update-coding-question?&qid=${(el?.question as QuestionAttrs)?.id}`}>Update</IonButton>}
          </li>
        ))}</ul>
        {!topic_detail_state && <IonSpinner name="dots" />}
      </IonContent>
    </IonPage>
  );
};

export default Topic;
