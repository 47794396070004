import _ from "lodash";
import Joi from "joi";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import { useHistory, useLocation } from "react-router";
import { errorToast, postQuestionCreate } from "../apis";
import { addOutline, closeOutline } from "ionicons/icons";
import TestCaseCard from "../components/question/TestCaseCard";
import { insertQuestion } from "../redux/reducers/questionState";
import { ILanguage, IQuestionDifficulty, IQuestionType } from '@com.xcodeclazz/monolithic-common/build/constants/questions';
import { QuestionsPayloadJoi_CreateQuestion, QuestionsPayload_CreateQuestion } from "@com.xcodeclazz/monolithic-common/build/payloads/questions";
import { IonButton, IonButtons, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';

interface IIndex {
  id: number;
}

interface xIo extends IIndex {
  input: string;
  output: string;
}

interface xIText extends IIndex {
  body: string;
}

export interface xITest extends xIo {
  title: string;
}

export interface xICode extends IIndex {
  lang: ILanguage;
  main: boolean;
  name: string;
  code: string;
}

const CreateCodingQuestion: React.FC<{}> = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedDifficulty, setSelectedDifficulty] = useState<IQuestionDifficulty>(IQuestionDifficulty.Easy);
  const [selectedLang, setSelectedLang] = useState<ILanguage>(ILanguage.Java);

  const titleRef = React.createRef<HTMLIonInputElement>();
  const bodyTextRef = React.createRef<HTMLIonTextareaElement>();
  const bodyCodeRef = React.createRef<HTMLIonTextareaElement>();
  const isActiveRef = React.createRef<HTMLIonInputElement>();
  const attempAllowRef = React.createRef<HTMLIonInputElement>();
  const paneltyRef = React.createRef<HTMLIonInputElement>();
  const pointsRef = React.createRef<HTMLIonInputElement>();

  const [codingVisibleTests, setCodingVisibleTests] = useState<xITest[]>([]);
  const [codingHiddenTests, setCodingHiddenTests] = useState<xITest[]>([]);
  const [codingConstraints, setCodingConstraints] = useState<xIText[]>([]);
  const [codingSolutions, setCodingSolutions] = useState<xICode[]>([]);
  const [codingExamples, setCodingExamples] = useState<xIText[]>([]);
  const [codingSources, setCodingSources] = useState<xICode[]>([]);
  const [codingIo, setCodingIo] = useState<xIo[]>([]);

  const cid = new URLSearchParams(location.search)?.get('cid');
  const tid = new URLSearchParams(location.search)?.get('tid');

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateQuestion = () => {
    if (!(cid && tid)) return;

    const title = titleRef.current?.value?.toString();
    const bodyText = bodyTextRef.current?.value?.toString();
    const bodyCode = bodyCodeRef.current?.value?.toString();
    const isActive = isActiveRef.current?.value?.toString();
    const attempAllow = attempAllowRef.current?.value?.toString();
    const panelty = paneltyRef.current?.value?.toString();
    const points = pointsRef.current?.value?.toString();

    if (title == "" || bodyText == "" || attempAllow == "" || panelty == "" || points == "") return;

    const data: QuestionsPayload_CreateQuestion = {
      body: {
        text: bodyText || "",
        code: bodyCode || "",
      },
      title: title || "",
      topic: tid,
      course: cid,
      points: +(points || 0),
      panelty: +(panelty || 0),
      language: selectedLang,
      attempAllow: +(attempAllow || 0),
      type: IQuestionType.Coding,
      difficulty: selectedDifficulty,
      isActive: isActive?.toString() == "true" ? true : false,
      coding: {
        io: codingIo.map((e) => { _.unset(e, 'id'); return e; }),
        sources: codingSources.map((e) => { _.unset(e, 'id'); return e; }),
        examples: codingExamples.map((e) => { _.unset(e, 'id'); return e; }),
        solutions: codingSolutions.map((e) => { _.unset(e, 'id'); return e; }),
        constraints: codingConstraints.map((e) => { _.unset(e, 'id'); return e; }),
        hidden_tests: codingHiddenTests.map((e) => { _.unset(e, 'id'); return e; }),
        visible_tests: codingVisibleTests.map((e) => { _.unset(e, 'id'); return e; }),
      }
    };

    const { error, value } = Joi.object(QuestionsPayloadJoi_CreateQuestion).validate(data);
    if (!error) {
      setLoading(true);
      postQuestionCreate(value, res => {
        dispatch(insertQuestion(res.data));
        if (titleRef.current) titleRef.current.value = "";
        if (bodyTextRef.current) bodyTextRef.current.value = "";
        if (bodyCodeRef.current) bodyCodeRef.current.value = "";
        if (isActiveRef.current) isActiveRef.current.value = "";
        if (attempAllowRef.current) attempAllowRef.current.value = "";
        if (paneltyRef.current) paneltyRef.current.value = "";
        if (pointsRef.current) pointsRef.current.value = "";
        setLoading(false);
        history.goBack();
      }, errorToast);
    } else {
      Capacitor.toast(error.message, "long");
      setLoading(false);
    }
  };

  const onSelectedLangChange = (lang: ILanguage) => {
    setSelectedLang(lang);
    setCodingSources(codingSources.map(e => { return { ...e, lang: lang  } }));
    setCodingSolutions(codingSolutions.map(e => { return { ...e, lang: lang  } }));
  };

  const handleCodingSolutionChange = (d: xICode, f: xICode) => setCodingSolutions(codingSolutions.map((o) => o.id === d.id ? f : o));
  const handleCodingSourceChange = (d: xICode, f: xICode) => setCodingSources(codingSources.map((o) => o.id === d.id ? f : o));
  const handleCodingHiddenTestChange = (d: xITest, f: xITest) => setCodingHiddenTests(codingHiddenTests.map((o) => o.id === d.id ? f : o));
  const handleCodingVisibleTestChange = (d: xITest, f: xITest) => setCodingVisibleTests(codingVisibleTests.map((o) => o.id === d.id ? f : o));
  const handleCodingConstraintChange = (d: xIText, f: xIText) => setCodingConstraints(codingConstraints.map((o) => o.id === d.id ? f : o));
  const handleCodingExampleChange = (d: xIText, f: xIText) => setCodingExamples(codingExamples.map((o) => o.id === d.id ? f : o));
  const handleCodingIoChange = (d: xIo, f: xIo) => setCodingIo(codingIo.map((o) => o.id === d.id ? f : o));

  const handleRemoveCodingSolution = (id: number) => setCodingSolutions(codingSolutions.filter(o => o.id !== id));
  const handleRemoveCodingSource = (id: number) => setCodingSources(codingSources.filter(o => o.id !== id));
  const handleRemoveCodingHiddenTest = (id: number) => setCodingHiddenTests(codingHiddenTests.filter(o => o.id !== id));
  const handleRemoveCodingVisibleTest = (id: number) => setCodingVisibleTests(codingVisibleTests.filter(o => o.id !== id));
  const handleRemoveCodingConstraint = (id: number) => setCodingConstraints(codingConstraints.filter(o => o.id !== id));
  const handleRemoveCodingExample = (id: number) => setCodingExamples(codingExamples.filter(o => o.id !== id));
  const handleRemoveCodingIo = (id: number) => setCodingIo(codingIo.filter(o => o.id !== id));
  
  const handleAddCodingSolution = () => setCodingSolutions([...codingSolutions, { id: Date.now(), code: "", lang: selectedLang, main: false, name: "" }]);
  const handleAddCodingSource = () => setCodingSources([...codingSources, { id: Date.now(), code: "", lang: selectedLang, main: false, name: "" }]);
  const handleAddCodingHiddenTest = () => setCodingHiddenTests([...codingHiddenTests, { id: Date.now(), title: "", input: "", output: "" }]);
  const handleAddCodingVisibleTest = () => setCodingVisibleTests([...codingVisibleTests, { id: Date.now(), title: "", input: "", output: "" }]);
  const handleAddCodingConstraint = () => setCodingConstraints([...codingConstraints, { id: Date.now(), body: "" }]);
  const handleAddCodingExample = () => setCodingExamples([...codingExamples, { id: Date.now(), body: "" }]);
  const handleAddCodingIo = () => setCodingIo([...codingIo, { id: Date.now(), input: "", output: "" }]);

  return (
    <IonPage>
      <Header codeParserIcon onCreate={onCreateQuestion} loading={loading} />
      <IonContent fullscreen>
        <IonGrid className="h-full overflow-hidden">
          <IonRow className="h-full overflow-hidden">
            <IonCol size="12" sizeMd="5" className="h-full overflow-scroll">
              <IonGrid>
                <IonRow>
                  <IonCol><h1 className="capitalize">Create {IQuestionType.Coding}</h1></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonInput ref={titleRef} label="Title" labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonTextarea ref={bodyTextRef} label="Body" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                  <IonCol><IonTextarea ref={bodyCodeRef} label="Code" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedLang} labelPlacement="floating" fill="outline" placeholder="Language" onIonChange={(e) => onSelectedLangChange(e.detail.value)}>
                      {_.map(ILanguage, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedDifficulty} labelPlacement="floating" fill="outline" placeholder="Difficulty" onIonChange={(e) => setSelectedDifficulty(e.detail.value)}>
                      {_.map(IQuestionDifficulty, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={isActiveRef} label="Active" labelPlacement="floating" fill="outline" placeholder="true" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={attempAllowRef} type="number" label="Attemp Allow" labelPlacement="floating" fill="outline" placeholder="10" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={paneltyRef} type="number" label="Panelty" labelPlacement="floating" fill="outline" placeholder="5" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={pointsRef} type="number" label="Points" labelPlacement="floating" fill="outline" placeholder="20" />
                  </IonCol>
                </IonRow>
                <IonRow><IonCol><h1>Hidden Test Cases</h1></IonCol></IonRow>
                { codingHiddenTests.length == 0 && <IonItem className="text-sm">No cases found</IonItem> }
                <IonGrid>
                  <IonRow>{_.map(codingHiddenTests, (el, idx) => <IonCol key={idx} size="6"><TestCaseCard lang={selectedLang} case={el} sources={codingSolutions} /></IonCol>)}</IonRow>
                </IonGrid>
                <IonRow><IonCol><h1>Visible Test Cases</h1></IonCol></IonRow>
                { codingVisibleTests.length == 0 && <IonItem className="text-sm">No cases found</IonItem> }
                <IonGrid>
                  <IonRow>{_.map(codingVisibleTests, (el, idx) => <IonCol key={idx} size="6"><TestCaseCard lang={selectedLang} case={el} sources={codingSolutions} /></IonCol>)}</IonRow>
                </IonGrid>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="7" className="h-full overflow-scroll">
              <IonGrid className="h-full">
                <IonRow>
                  <IonCol size="11"><h1>Solutions</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingSolution}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingSolutions.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonButton size="small" fill="clear" disabled>{el.lang}</IonButton>
                        <IonTextarea value={el.code} rows={4} placeholder="Code..." fill="solid" onIonChange={e => handleCodingSolutionChange(el, { ...el, code: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonInput value={el.name} placeholder="Filename" fill="solid" onIonChange={e => handleCodingSolutionChange(el, { ...el, name: e.target.value?.toString() || "" })} />
                          <IonCheckbox checked={el.main} onIonChange={e => handleCodingSolutionChange(el, { ...el, main: !el.main })} />
                          <IonButton onClick={handleAddCodingSolution}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingSolution(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Sources</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingSource}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingSources.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonButton size="small" fill="clear" disabled>{el.lang}</IonButton>
                        <IonTextarea value={el.code} rows={4} placeholder="Code..." fill="solid" onIonChange={e => handleCodingSourceChange(el, { ...el, code: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonInput value={el.name} placeholder="Filename" fill="solid" onIonChange={e => handleCodingSourceChange(el, { ...el, name: e.target.value?.toString() || "" })} />
                          <IonCheckbox checked={el.main} onIonChange={e => handleCodingSourceChange(el, { ...el, main: !el.main })} />
                          <IonButton onClick={handleAddCodingSource}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingSource(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Hidden Tests</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingHiddenTest}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingHiddenTests.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonTextarea value={el.input} rows={2} placeholder="Input..." fill="solid" onIonChange={e => handleCodingHiddenTestChange(el, { ...el, input: e.target.value?.toString() || "" })} />
                        <IonTextarea value={el.output} rows={2} placeholder="Output..." fill="solid" onIonChange={e => handleCodingHiddenTestChange(el, { ...el, output: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonInput value={el.title} placeholder="Test Name" fill="solid" onIonChange={e => handleCodingHiddenTestChange(el, { ...el, title: e.target.value?.toString() || "" })} />
                          <IonButton onClick={handleAddCodingHiddenTest}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingHiddenTest(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Visible Tests</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingVisibleTest}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingVisibleTests.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonTextarea value={el.input} rows={2} placeholder="Input..." fill="solid" onIonChange={e => handleCodingVisibleTestChange(el, { ...el, input: e.target.value?.toString() || "" })} />
                        <IonTextarea value={el.output} rows={2} placeholder="Output..." fill="solid" onIonChange={e => handleCodingVisibleTestChange(el, { ...el, output: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonInput value={el.title} placeholder="Test Name" fill="solid" onIonChange={e => handleCodingVisibleTestChange(el, { ...el, title: e.target.value?.toString() || "" })} />
                          <IonButton onClick={handleAddCodingVisibleTest}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingVisibleTest(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Constraints</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingConstraint}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingConstraints.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonTextarea value={el.body} rows={4} placeholder="Constraint" fill="solid" onIonChange={e => handleCodingConstraintChange(el, { ...el, body: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonButton onClick={handleAddCodingConstraint}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingConstraint(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Examples</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingExample}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingExamples.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonTextarea value={el.body} rows={4} placeholder="Example" fill="solid" onIonChange={e => handleCodingExampleChange(el, { ...el, body: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonButton onClick={handleAddCodingExample}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingExample(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
                <IonRow>
                  <IonCol size="11"><h1>Io</h1></IonCol>
                  <IonCol size="1" className="flex justify-center items-center"><IonButton onClick={handleAddCodingIo}>Add</IonButton></IonCol>
                </IonRow>
                <IonRow>
                  {codingIo.map((el, i) => (
                    <IonCol key={i} size="6">
                      <IonCard className="ion-no-margin">
                        <IonTextarea value={el.input} rows={2} placeholder="Input..." fill="solid" onIonChange={e => handleCodingIoChange(el, { ...el, input: e.target.value?.toString() || "" })} />
                        <IonTextarea value={el.output} rows={2} placeholder="Output..." fill="solid" onIonChange={e => handleCodingIoChange(el, { ...el, output: e.target.value?.toString() || "" })} />
                        <IonButtons>
                          <IonButton onClick={handleAddCodingIo}><IonIcon slot="icon-only" icon={addOutline} /></IonButton>
                          <IonButton onClick={e => handleRemoveCodingIo(el.id)}><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>
                        </IonButtons>
                      </IonCard>
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateCodingQuestion;
