import _ from "lodash";
import Joi from "joi";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { errorToast, getTopic, patchTopic } from "../apis";
import { TopicsResponse_ShowTopic } from "@com.xcodeclazz/monolithic-common/build/responses/topics";
import { IonCard, IonCol, IonContent, IonGrid, IonInput, IonPage, IonRippleEffect, IonRow } from '@ionic/react';
import { TopicsPayloadJoi_UpdateTopic, TopicsPayload_UpdateTopic } from "@com.xcodeclazz/monolithic-common/build/payloads/topics";

const UpdateTopic: React.FC<{}> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [topic, setTopic] = useState<TopicsResponse_ShowTopic>();

  const [thumbnailSrc, setThumbnailSrc] = useState<string>("https://via.placeholder.com/500x300");

  const levelRef = React.createRef<HTMLIonInputElement>();
  const titleRef = React.createRef<HTMLIonInputElement>();
  const subtitleRef = React.createRef<HTMLIonInputElement>();
  const isActiveRef = React.createRef<HTMLIonInputElement>();
  const eligibilityRef = React.createRef<HTMLIonInputElement>();

  const cid = new URLSearchParams(location.search)?.get('cid');
  const tid = new URLSearchParams(location.search)?.get('tid');

  const [loading, setLoading] = useState<boolean>(false);

  const onUpdateTopic = () => {
    if (!cid || !tid) return;

    const level = levelRef.current?.value?.toString();
    const title = titleRef.current?.value?.toString();
    const subtitle = subtitleRef.current?.value?.toString();
    const isActive = isActiveRef.current?.value?.toString();
    const eligibility = eligibilityRef.current?.value?.toString();

    if (level == "" || title == "" || subtitle == "" || eligibility == "") return;

    const data: TopicsPayload_UpdateTopic = {
      topic: tid,
      course: cid,

      title,
      subtitle,
      level: +(level || 0),
      thumbnail: thumbnailSrc,
      isActive: isActive?.toString() == "true" ? true : false,
      eligibility: (eligibility || "").split(",").map((e) => e.trim()),
    };

    const { error, value } = Joi.object(TopicsPayloadJoi_UpdateTopic).validate(data);
    if (!error) {
      setLoading(true);
      patchTopic(value, res => {
        setLoading(false);
        history.goBack();
      }, errorToast);
    } else {
      Capacitor.toast(error.message, 'long');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!cid || !tid) return;
    getTopic({ cid, tid }, res => setTopic(res.data), errorToast);
  }, []);

  useEffect(() => {
    if (!topic) return;
    setThumbnailSrc(topic.topic.thumbnail);
    if (levelRef.current) levelRef.current.value = topic.topic.level;
    if (titleRef.current) titleRef.current.value = topic.topic.title;
    if (subtitleRef.current) subtitleRef.current.value = topic.topic.subtitle;
    if (isActiveRef.current) isActiveRef.current.value = (''+topic.topic.isActive);
    if (eligibilityRef.current) eligibilityRef.current.value = topic.topic.eligibility.join(", ");    
  }, [topic]);

  return (
    <IonPage>
      <Header onUpdate={onUpdateTopic} loading={loading} />
      <IonContent fullscreen>
        <IonGrid className="h-full">
          <IonRow className="h-full">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonInput value={thumbnailSrc} label="Thumbnail" labelPlacement="floating" fill="outline" placeholder="https://example.com?lang30px.png" onIonChange={e => setThumbnailSrc(e.target.value?.toString() || thumbnailSrc)} />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput ref={titleRef} label="Title" labelPlacement="floating" fill="outline" />
                  </IonCol>
                  <IonCol size="12" sizeMd="12" sizeLg="6">
                    <IonInput ref={subtitleRef} label="Subtitle" labelPlacement="floating" fill="outline" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="6">
                    <IonInput ref={levelRef} label="Level" labelPlacement="floating" fill="outline" placeholder="1" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="6">
                    <IonInput ref={isActiveRef} label="Active" labelPlacement="floating" fill="outline" placeholder="false" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={eligibilityRef} label="Eligibility" labelPlacement="floating" fill="outline" placeholder="Brain, Variables" />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonGrid className="w-full">
                <IonRow className="justify-center">
                  <IonCard className="ion-activatable overflow-hidden">
                    <IonRippleEffect></IonRippleEffect>
                    <img src={thumbnailSrc} onError={e => setThumbnailSrc("https://via.placeholder.com/500x300")} className="h-full w-full" alt="thumbnail preview" />
                  </IonCard>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UpdateTopic;
