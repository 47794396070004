import _ from "lodash";
import React, { useEffect, useState } from 'react';
import Header from "../components/common/Header";
import { errorToast, getCoursesAll } from "../apis";
import { useDispatch, useSelector } from "react-redux";
import { saveCourses } from "../redux/reducers/courseState";
import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react';
import { CoursesResponse_ShowCourses } from "@com.xcodeclazz/monolithic-common";

const Courses: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const [isFetching, setFetching] = useState<boolean>(false);
  const courses_state: CoursesResponse_ShowCourses = useSelector((state: any) => state.courseState.courses);

  const getAllActiveCourses = () => {
    setFetching(true);
    getCoursesAll(res => {
      dispatch(saveCourses(res.data));
      setFetching(false);
    }, error => {
      errorToast(error);
      setFetching(false);
    });
  }
  useEffect(() => { if (!courses_state?.courses.length) getAllActiveCourses() }, []);

  return (
    <IonPage>
      <Header createCourse={`/create-course`} />
      <IonContent fullscreen className="ion-padding">
        <h1>Courses</h1>
        <ul>{_.map(courses_state?.courses, (el, idx) => (
          <li key={idx}>
            {el.title} &nbsp;
            <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/course?cid=${el.id}`}>View</IonButton>
            <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/update-course?cid=${el.id}`}>Update</IonButton>
          </li>
        ))}</ul>
        {isFetching && <IonSpinner name="dots" />}
      </IonContent>
    </IonPage>
  );
};

export default Courses;
