import _ from "lodash";
import Joi from "joi";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import { useHistory, useLocation } from "react-router";
import { errorToast, postQuestionCreate } from "../apis";
import { addOutline, closeOutline } from "ionicons/icons";
import { insertQuestion } from "../redux/reducers/questionState";
import { ILanguage, IQuestionDifficulty, IQuestionType } from '@com.xcodeclazz/monolithic-common/build/constants/questions';
import { QuestionsPayloadJoi_CreateQuestion, QuestionsPayload_CreateQuestion } from "@com.xcodeclazz/monolithic-common/build/payloads/questions";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';

interface xIMcq { 
  id: number;
  body: string;
  color: string;
  checked: boolean;
}

const CreateMcqQuestion: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedDifficulty, setSelectedDifficulty] = useState<IQuestionDifficulty>(IQuestionDifficulty.Easy);
  const [selectedLang, setSelectedLang] = useState<ILanguage>(ILanguage.Java);

  const titleRef = React.createRef<HTMLIonInputElement>();
  const bodyTextRef = React.createRef<HTMLIonTextareaElement>();
  const bodyCodeRef = React.createRef<HTMLIonTextareaElement>();
  const isActiveRef = React.createRef<HTMLIonInputElement>();
  const attempAllowRef = React.createRef<HTMLIonInputElement>();
  const paneltyRef = React.createRef<HTMLIonInputElement>();
  const pointsRef = React.createRef<HTMLIonInputElement>();
  const solutionRef = React.createRef<HTMLIonInputElement>();
  const [options, setOptions] = useState<xIMcq[]>([]);

  const cid = new URLSearchParams(location.search)?.get('cid');
  const tid = new URLSearchParams(location.search)?.get('tid');

  const [loading, setLoading] = useState<boolean>(false);

  const onCreateQuestion = () => {
    if (!(cid && tid)) return;

    const title = titleRef.current?.value?.toString();
    const bodyText = bodyTextRef.current?.value?.toString();
    const bodyCode = bodyCodeRef.current?.value?.toString();
    const isActive = isActiveRef.current?.value?.toString();
    const attempAllow = attempAllowRef.current?.value?.toString();
    const panelty = paneltyRef.current?.value?.toString();
    const points = pointsRef.current?.value?.toString();
    const solution = solutionRef.current?.value?.toString();

    if (title == "" || bodyText == "" || attempAllow == "" || panelty == "" || points == "" || solution == "") return;

    const data: QuestionsPayload_CreateQuestion = {
      body: {
        text: bodyText || "",
        code: bodyCode || "",
      },
      title: title || "",
      topic: tid,
      course: cid,
      points: +(points || 0),
      panelty: +(panelty || 0),
      language: selectedLang,
      type: IQuestionType.Mcq,
      attempAllow: +(attempAllow || 0),
      difficulty: selectedDifficulty,
      isActive: isActive?.toString() == "true" ? true : false,
      mcq: {
        solution: (solution || "").split(",").map((e) => +e.trim()),
        options: options.map((e) => { _.unset(e, 'id'); return e; }),
      },
    };

    const { error, value } = Joi.object(QuestionsPayloadJoi_CreateQuestion).validate(data);
    if (!error) {
      setLoading(true);
      postQuestionCreate(value, res => {
        dispatch(insertQuestion(res.data));
        if (titleRef.current) titleRef.current.value = "";
        if (bodyTextRef.current) bodyTextRef.current.value = "";
        if (bodyCodeRef.current) bodyCodeRef.current.value = "";
        if (isActiveRef.current) isActiveRef.current.value = "";
        if (attempAllowRef.current) attempAllowRef.current.value = "";
        if (paneltyRef.current) paneltyRef.current.value = "";
        if (pointsRef.current) pointsRef.current.value = "";
        if (solutionRef.current) solutionRef.current.value = "";
        setLoading(false);
        setOptions([]);
        history.goBack();
      }, errorToast);
    } else {
      Capacitor.toast(error.message, 'long');
      setLoading(false);
    }
  };

  const handleInputChange = (d: xIMcq, value: string) => {
    const updatedOptions = options.map((option) => option.id === d.id ? { ...option, body: value } : option);
    setOptions(updatedOptions);
  };
  const handleRemoveOption = (id: number) => {
    const updatedOptions = options.filter((option) => option.id !== id);
    setOptions(updatedOptions);
  };
  const handleAddOption = () => {
    const newOption: xIMcq = { id: Date.now(), body: "", checked: false, color: "" };
    setOptions([...options, newOption]);
  };

  return (
    <IonPage>
      <Header onCreate={onCreateQuestion} loading={loading} />
      <IonContent fullscreen>
        <IonGrid className="h-full">
          <IonRow className="h-full">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol><h1 className="capitalize">Create {IQuestionType.Mcq}</h1></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonInput ref={titleRef} label="Title" labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonTextarea ref={bodyTextRef} label="Body" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                  <IonCol><IonTextarea ref={bodyCodeRef} label="Code" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedLang} labelPlacement="floating" fill="outline" placeholder="Language" onIonChange={(e) => setSelectedLang(e.detail.value)}>
                      {_.map(ILanguage, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedDifficulty} labelPlacement="floating" fill="outline" placeholder="Difficulty" onIonChange={(e) => setSelectedDifficulty(e.detail.value)}>
                      {_.map(IQuestionDifficulty, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={isActiveRef} label="Active" labelPlacement="floating" fill="outline" placeholder="true" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={attempAllowRef} type="number" label="Attemp Allow" labelPlacement="floating" fill="outline" placeholder="10" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={paneltyRef} type="number" label="Panelty" labelPlacement="floating" fill="outline" placeholder="5" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={pointsRef} type="number" label="Points" labelPlacement="floating" fill="outline" placeholder="20" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={solutionRef} label="Solution" labelPlacement="floating" fill="outline" placeholder="0, 1, 3" />
                  </IonCol>
                </IonRow>
                <IonRow><IonCol><IonButton expand="full" onClick={handleAddOption}>Add Option</IonButton></IonCol></IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonGrid className="h-full">
                <IonRow><IonCol><h1>Options</h1></IonCol></IonRow>
                {options.map((option, i) => (
                  <IonRow key={i}>
                    <IonCol size="10">
                      <IonTextarea value={option.body} rows={4} label={`Option ${i + 1}`} labelPlacement="floating" fill="outline" onIonChange={e => handleInputChange(option, e.target.value?.toString() || "")} />
                    </IonCol>
                    <IonCol size="2">
                      <IonButtons>
                        <IonButton onClick={() => handleRemoveOption(option.id)}>
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                        <IonButton onClick={handleAddOption}>
                          <IonIcon icon={addOutline} />
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CreateMcqQuestion;
