import _ from "lodash";
import Joi from "joi";
import AceEditor from "react-ace";
import { useSelector } from "react-redux";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { errorToast, getQuestionRaw, patchQuestion } from "../apis";
import { getEditorTheme, isValidObjectId } from "../utils/common/helper";
import { IonContent, IonFooter, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { QuestionsPayloadJoi_UpdateQuestion } from "@com.xcodeclazz/monolithic-common/build/payloads/questions";
import { QuestionsResponse_ShowRawQuestion } from "@com.xcodeclazz/monolithic-common/build/responses/questions";

const tabSpace = 2;
const UpdateQuestion: React.FC<{}> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [fontSize] = useState<number>(18);
  const [showGutter] = useState<boolean>(true);
  const aceEditorRef = React.createRef<AceEditor>();
  const theme_state: string = useSelector((state: any) => state.uiState.theme);

  const qid = new URLSearchParams(location.search)?.get('qid');

  const [error, setError] = useState<Joi.ValidationError | undefined>();
  const [code, setCode] = useState<string>(JSON.stringify({}));

  const getQuestionById = (qid: string) => getQuestionRaw({ qid }, res => {
    const code = JSON.stringify((res.data as QuestionsResponse_ShowRawQuestion).question, null, tabSpace);
    setCode(code);
  }, errorToast);

  useEffect(() => {
    if (!qid) {}
    else if (!isValidObjectId(qid)) {}
    else getQuestionById(qid);
  }, []);

  const updateCode = (code: string, event: any) => {
    try { JSON.parse(code); } catch (err) { }
    try {
      const { error } = Joi.object(QuestionsPayloadJoi_UpdateQuestion).validate(JSON.parse(code));
      setError(error);
      setCode(code);
    }
    catch (err) {}
  };

  const onUpdateQuestion = () => {
    try { JSON.parse(code); } catch (err) { }
    const { error, value } = Joi.object(QuestionsPayloadJoi_UpdateQuestion).validate(JSON.parse(code));
    setError(error);
    if (!error) {
      patchQuestion(value, res => {
        history.goBack();
      }, errorToast);
    } else Capacitor.toast(error.message, 'long');
  };

  return (
    <IonPage>
      <Header onUpdate={onUpdateQuestion} />
      <IonContent fullscreen>
        <AceEditor
          ref={aceEditorRef}
          name={`ID-${Math.random()}`}
          mode={'json5'}
          width="100"
          theme={getEditorTheme()}
          fontSize={fontSize}
          highlightActiveLine={true}
          showPrintMargin={true}
          readOnly={false}
          showGutter={showGutter}
          wrapEnabled={false}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => { editor.renderer.setPadding(15) }}
          style={{ height: "100%", backgroundColor: "transparent" }}
          value={code}
          // debounceChangePeriod={1000}
          onChange={updateCode}
          setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              showLineNumbers: true,
              tabSize: tabSpace,
          }}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>{error?.message}</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default UpdateQuestion;
