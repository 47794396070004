import _ from "lodash";
import React, { useEffect } from 'react';
import { errorToast, getTopics } from "../apis";
import Header from "../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isValidObjectId } from "../utils/common/helper";
import { saveCourseTopics } from "../redux/reducers/topicState";
import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react';
import { TopicsResponse_ShowTopics } from "@com.xcodeclazz/monolithic-common";

const Course: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const cid = new URLSearchParams(location.search)?.get('cid');
  const course_topic_state: { [cid: string]: TopicsResponse_ShowTopics } = useSelector((state: any) => state.topicState.courseTopics);

  const getCourseTopics = (cid: string) => getTopics({ cid }, res => dispatch(saveCourseTopics(res.data)), errorToast);
  useEffect(() => { 
    if (!cid || !isValidObjectId(cid!)) {}
    else getCourseTopics(cid);
  }, []);

  return (
    <IonPage>
      <Header createTopic={`/create-topic?cid=${cid}`} />
      <IonContent fullscreen className="ion-padding">
        <h1>Topics</h1>
        {course_topic_state[cid!]?.topic?.topics?.length === 0 && <strong>No Topics Found...</strong>}
        <ul>{_.map(course_topic_state[cid!]?.topic?.topics, (el, idx) => (
          <li key={idx}>
            {el.title} &nbsp;
            <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/topic?cid=${cid}&tid=${el.id}`}>View</IonButton>
            <IonButton routerDirection="none" size="small" fill="clear" routerLink={`/update-topic?cid=${cid}&tid=${el.id}`}>Update</IonButton>
          </li>
        ))}</ul>
        {!course_topic_state[cid!] && <IonSpinner name="dots" />}
      </IonContent>
    </IonPage>
  );
};

export default Course;
