import _ from "lodash";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import React, { useEffect, useState } from 'react';
import PrivateRoute from "./components/PrivateRoute";
import NonPrivateRoute from "./components/NonPrivateRoute";
import { IonReactRouter } from '@ionic/react-router';

import { IonApp, IonLoading, IonRouterOutlet, IonSpinner, IonTabBar, IonTabs, setupIonicReact } from '@ionic/react';

import Auth from "./pages/Auth";
import Login from "./pages/Login";
import Topic from "./pages/Topic";
import Course from "./pages/Course";
import Courses from "./pages/Courses";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import CreateTopic from "./pages/CreateTopic";
import UpdateTopic from "./pages/UpdateTopic";
import CreateCourse from "./pages/CreateCourse";
import UpdateCourse from "./pages/UpdateCourse";
import UpdateQuestion from "./pages/UpdateQuestion";
import UpdateMcqQuestion from "./pages/UpdateMcqQuestion";
import CreateMcqQuestion from "./pages/CreateMcqQuestion";
import CreateFillQuestion from "./pages/CreateFillQuestion";
import UpdateAnyoneQuestion from "./pages/UpdateAnyoneQuestion";
import UpdateCodingQuestion from "./pages/UpdateCodingQuestion";
import CreateCodingQuestion from "./pages/CreateCodingQuestion";
import CreateAnyoneQuestion from "./pages/CreateAnyoneQuestion";

import ApplicationContextProvider from './data/ApplicationContextProvider';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Ace editor styles */
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/ext-prompt";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-tomorrow_night_bright";
import "ace-builds/src-noconflict/theme-xcode";

import "brace";
import "brace/ext/searchbox";
import "brace/ext/language_tools";

/* Theme variables */
import './theme/variables.css';
import "./theme/theme.css";

setupIonicReact();

export const currentPath = () => {
  const match = window.location.pathname.match(/^\/[^?#]*/);
  return match ? match[0] : '';
}
export const components = {
  dashboard: {
    path: "/",
    Component: Dashboard,
  },
  auth: {
    path: "/auth",
    Component: Auth,
  },
  login: {
    path: "/login",
    Component: Login,
  },
  register: {
    path: "/register",
    Component: Register,
  },
  topic: {
    path: "/topic",
    Component: Topic,
  },
  createTopic: {
    path: "/create-topic",
    Component: CreateTopic,
  },
  updateTopic: {
    path: "/update-topic",
    Component: UpdateTopic,
  },
  course: {
    path: "/course",
    Component: Course,
  },
  courses: {
    path: "/courses",
    Component: Courses,
  },
  createCourse: {
    path: "/create-course",
    Component: CreateCourse,
  },
  updateCourse: {
    path: "/update-course",
    Component: UpdateCourse,
  },
  createMcqQuestion: {
    path: "/create-mcq-question",
    Component: CreateMcqQuestion,
  },
  createFillQuestion: {
    path: "/create-fill-question",
    Component: CreateFillQuestion,
  },
  createCodingQuestion: {
    path: "/create-coding-question",
    Component: CreateCodingQuestion,
  },
  createAnyoneQuestion: {
    path: "/create-anyone-question",
    Component: CreateAnyoneQuestion,
  },
  updateQuestion: {
    path: "/update-question",
    Component: UpdateQuestion,
  },
  updateMcqQuestion: {
    path: "/update-mcq-question",
    Component: UpdateMcqQuestion,
  },
  updateAnyoneQuestion: {
    path: "/update-anyone-question",
    Component: UpdateAnyoneQuestion,
  },
  updateCodingQuestion: {
    path: "/update-coding-question",
    Component: UpdateCodingQuestion,
  }
};
const App: React.FC = () => {
  const [wait, setWait] = useState<boolean>(true);
  const tabBarVisible = useSelector((state: any) => state.uiState.tabBarVisible);

  useEffect(() => {
    const displayChanges = () => setWait(false);
    window.addEventListener('storage', displayChanges);
    return () => window.removeEventListener('storage', displayChanges);
  }, []);

  const getRoutes = () => {
    return (
      <IonRouterOutlet id="main-drawer" animated={false}>
        <NonPrivateRoute path={components.auth.path} component={components.auth.Component} redirect={components.dashboard.path} exact />
        {/* <NonPrivateRoute path={components.register.path} component={components.register.Component} redirect={components.dashboard.path} exact /> */}
        <PrivateRoute path={components.topic.path} component={components.topic.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.course.path} component={components.course.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.courses.path} component={components.courses.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createTopic.path} component={components.createTopic.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateTopic.path} component={components.updateTopic.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createCourse.path} component={components.createCourse.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateCourse.path} component={components.updateCourse.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createMcqQuestion.path} component={components.createMcqQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createFillQuestion.path} component={components.createFillQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createCodingQuestion.path} component={components.createCodingQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.createAnyoneQuestion.path} component={components.createAnyoneQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateQuestion.path} component={components.updateQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateMcqQuestion.path} component={components.updateMcqQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateAnyoneQuestion.path} component={components.updateAnyoneQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.updateCodingQuestion.path} component={components.updateCodingQuestion.Component} redirect={components.auth.path} exact />
        <PrivateRoute path={components.dashboard.path} component={components.dashboard.Component} redirect={components.auth.path} exact />
        <Redirect to={components.auth.path} />
      </IonRouterOutlet>
    );
  };

  return (
    <IonApp>
      {wait && <IonLoading isOpen message="Please wait" duration={5000} />}
      <ApplicationContextProvider>
        <IonReactRouter>
          {getRoutes()}
        </IonReactRouter>
      </ApplicationContextProvider>
    </IonApp>
  )
};

export default App;
