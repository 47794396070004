import _ from 'lodash';
import axios from "axios";
import { useState } from "react";
import { routes } from '../../apis/routes';
import { xICode, xITest } from '../../pages/CreateCodingQuestion';
import { ILanguage } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import {
  IonCard,
  IonButton,
  IonSpinner,
  IonCardHeader,
  IonProgressBar,
  IonCardContent,
} from "@ionic/react";

const TestCaseCard: React.FC<{
  sources: xICode[],
  lang: ILanguage,
  case: xITest,
}> = (props) => {
  const [running, setRunning] = useState<boolean>(false);
  const [passed, setPassed] = useState<number>(0);

  const sources = props.sources.map((e) => { return { main: e.main, name: e.name, content: e.code } })
  const getHitPoint = (lang: ILanguage) => {
    switch (lang) {
      case ILanguage.Java:
        return { hit: routes.POST_COMPILERS_JAVA, extention: "java" };
      case ILanguage.Python:
        return { hit: routes.POST_COMPILERS_PYTHON, extention: "py" };
      case ILanguage.Javascript:
        return { hit: routes.POST_COMPILERS_NODE, extention: "js" };
      case ILanguage.Cpp:
        return { hit: routes.POST_COMPILERS_CPP, extention: "cpp" };
      default:
        return { hit: "/", extention: "exe" };
    }
  };

  const run = () => {
    if (sources.length == 0) return;
    setRunning(true);
    const direction = getHitPoint(props.lang);
    axios
      .post(direction.hit, {
        input: props.case.input,
        sources: sources,
      })
      .then((res) => {
        setRunning(false);
        const preach = res.data.result.executionResult.stdout?.replace("\r", "");
        setPassed(preach == props.case.output ? 1 : 2);
      })
      .catch((error) => {
        setRunning(false);
        console.log(error);
      });
  };

  return (
    <IonCard className='ion-no-margin'>
      <IonCardHeader>
        <div className="flex flex-row justify-between items-center">
          <strong className="text-lg">{props.case.title}</strong>
          {running ? (
            <IonSpinner />
          ) : (
            <IonButton onClick={run} size="small" fill="clear">
              Run
            </IonButton>
          )}
        </div>
      </IonCardHeader>
      <IonCardContent>
        <div className="flex flex-col">
          {props.case.input && <b>INPUT: {props.case.input}</b>}
          {props.case.output && <b>OUTPUT: {props.case.output}</b>}
        </div>
      </IonCardContent>
      {passed == 0 && <IonProgressBar value={1} color={"warning"} />}
      {passed == 1 && <IonProgressBar value={1} color={"success"} />}
      {passed == 2 && <IonProgressBar value={1} color={"danger"} />}
    </IonCard>
  );
};

export default TestCaseCard;