import _ from "lodash";
import Joi from "joi";
import Capacitor from "../utils/Capacitor";
import Header from "../components/common/Header";
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { addOutline, closeOutline } from "ionicons/icons";
import { errorToast, getQuestionRaw, patchQuestion } from "../apis";
import { QuestionsResponse_ShowRawQuestion } from "@com.xcodeclazz/monolithic-common/build/responses/questions";
import { ILanguage, IQuestionDifficulty, IQuestionType } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { QuestionsPayloadJoi_UpdateQuestion, QuestionsPayload_UpdateQuestion } from "@com.xcodeclazz/monolithic-common/build/payloads/questions";
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';

interface xIAnyone { 
  id: number;
  body: string;
  color: string;
  checked: boolean;
}

const UpdateAnyoneQuestion: React.FC<{}> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [question, setQuestion] = useState<QuestionsResponse_ShowRawQuestion>();

  const [selectedDifficulty, setSelectedDifficulty] = useState<IQuestionDifficulty>(IQuestionDifficulty.Easy);
  const [selectedLang, setSelectedLang] = useState<ILanguage>(ILanguage.Java);

  const titleRef = React.createRef<HTMLIonInputElement>();
  const bodyTextRef = React.createRef<HTMLIonTextareaElement>();
  const bodyCodeRef = React.createRef<HTMLIonTextareaElement>();
  const isActiveRef = React.createRef<HTMLIonInputElement>();
  const attempAllowRef = React.createRef<HTMLIonInputElement>();
  const paneltyRef = React.createRef<HTMLIonInputElement>();
  const pointsRef = React.createRef<HTMLIonInputElement>();
  const solutionRef = React.createRef<HTMLIonInputElement>();
  const [options, setOptions] = useState<xIAnyone[]>([]);

  const qid = new URLSearchParams(location.search)?.get('qid');

  const [loading, setLoading] = useState<boolean>(false);

  const onUpdateQuestion = () => {
    if (!(qid && question?.question)) return;

    const title = titleRef.current?.value?.toString();
    const bodyText = bodyTextRef.current?.value?.toString();
    const bodyCode = bodyCodeRef.current?.value?.toString();
    const isActive = isActiveRef.current?.value?.toString();
    const attempAllow = attempAllowRef.current?.value?.toString();
    const panelty = paneltyRef.current?.value?.toString();
    const points = pointsRef.current?.value?.toString();
    const solution = solutionRef.current?.value?.toString();

    if (title == "" || bodyText == "" || attempAllow == "" || panelty == "" || points == "" || solution == "") return;

    const data: QuestionsPayload_UpdateQuestion = {
      body: {
        text: bodyText || "",
        code: bodyCode || "",
      },
      title,
      question: qid,
      points: +(points || 0),
      panelty: +(panelty || 0),
      language: selectedLang,
      attempAllow: +(attempAllow || 0),
      type: IQuestionType.Anyone,
      difficulty: selectedDifficulty,
      isActive: isActive?.toString() == "true" ? true : false,
      anyone: {
        solution:  +(solution || -1),
        options: options.map((e) => { _.unset(e, 'id'); return e; }),
      },
    };

    const { error, value } = Joi.object(QuestionsPayloadJoi_UpdateQuestion).validate(data);
    if (!error) {
      setLoading(true);
      patchQuestion(value, res => {
        setLoading(false);
        history.goBack();
      }, errorToast);
    } else {
      Capacitor.toast(error.message, 'long');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!qid) return;
    getQuestionRaw({ qid }, res => setQuestion(res.data), errorToast);
  }, []);

  useEffect(() => {
    if (!question) return;
    setSelectedLang(question.question.language);
    setSelectedDifficulty(question.question.difficulty);
    if (titleRef.current) titleRef.current.value = question.question.title;
    if (pointsRef.current) pointsRef.current.value = question.question.points;
    if (paneltyRef.current) paneltyRef.current.value = question.question.panelty;
    if (bodyTextRef.current) bodyTextRef.current.value = question.question.body.text;
    if (bodyCodeRef.current) bodyCodeRef.current.value = question.question.body.code;
    if (isActiveRef.current) isActiveRef.current.value = (''+question.question.isActive);
    if (attempAllowRef.current) attempAllowRef.current.value = question.question.attempAllow;
    if (solutionRef.current && question.question.anyone) {
      solutionRef.current.value = question.question.anyone.solution;
      const op = question.question.anyone.options.map((e, i) => { return { id: i, ...e } });
      setOptions(op);
    }
  }, [question]);

  const handleInputChange = (d: xIAnyone, value: string) => {
    const updatedOptions = options.map((option) => option.id === d.id ? { ...option, body: value } : option);
    setOptions(updatedOptions);
  };
  const handleRemoveOption = (id: number) => {
    const updatedOptions = options.filter((option) => option.id !== id);
    setOptions(updatedOptions);
  };
  const handleAddOption = () => {
    const newOption: xIAnyone = { id: Date.now(), body: "", checked: false, color: "" };
    setOptions([...options, newOption]);
  };

  return (
    <IonPage>
      <Header onUpdate={onUpdateQuestion} loading={loading} />
      <IonContent fullscreen>
        <IonGrid className="h-full">
          <IonRow className="h-full">
            <IonCol size="12" sizeMd="6">
              <IonGrid>
                <IonRow>
                  <IonCol><h1 className="capitalize">Update {IQuestionType.Anyone}</h1></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonInput ref={titleRef} label="Title" labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol><IonTextarea ref={bodyTextRef} label="Body" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                  <IonCol><IonTextarea ref={bodyCodeRef} label="Code" rows={6} labelPlacement="floating" fill="outline" /></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedLang} labelPlacement="floating" fill="outline" placeholder="Language" onIonChange={(e) => setSelectedLang(e.detail.value)}>
                      {_.map(ILanguage, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonSelect value={selectedDifficulty} labelPlacement="floating" fill="outline" placeholder="Difficulty" onIonChange={(e) => setSelectedDifficulty(e.detail.value)}>
                      {_.map(IQuestionDifficulty, (el, idx) => (<IonSelectOption key={idx} value={el}>{el.toUpperCase()}</IonSelectOption>))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={isActiveRef} label="Active" labelPlacement="floating" fill="outline" placeholder="true" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={attempAllowRef} type="number" label="Attemp Allow" labelPlacement="floating" fill="outline" placeholder="10" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={paneltyRef} type="number" label="Panelty" labelPlacement="floating" fill="outline" placeholder="5" />
                  </IonCol>
                  <IonCol size="12" sizeMd="4" sizeLg="4">
                    <IonInput ref={pointsRef} type="number" label="Points" labelPlacement="floating" fill="outline" placeholder="20" />
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonInput ref={solutionRef} label="Solution" labelPlacement="floating" fill="outline" placeholder="0, 1, 3" />
                  </IonCol>
                </IonRow>
                <IonRow><IonCol><IonButton expand="full" onClick={handleAddOption}>Add Option</IonButton></IonCol></IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="12" sizeMd="6">
              <IonGrid className="h-full">
                <IonRow><IonCol><h1>Options</h1></IonCol></IonRow>
                {options.map((option, i) => (
                  <IonRow key={i}>
                    <IonCol size="10">
                      <IonTextarea value={option.body} rows={4} label={`Option ${i + 1}`} labelPlacement="floating" fill="outline" onIonChange={e => handleInputChange(option, e.target.value?.toString() || "")} />
                    </IonCol>
                    <IonCol size="2">
                      <IonButtons>
                        <IonButton onClick={() => handleRemoveOption(option.id)}>
                          <IonIcon icon={closeOutline} />
                        </IonButton>
                        <IonButton onClick={handleAddOption}>
                          <IonIcon icon={addOutline} />
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UpdateAnyoneQuestion;
