import _ from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { moonOutline } from "ionicons/icons";
import AuthState from "../../utils/common/auth-state";
import { setTheme } from "../../redux/reducers/uiState";
import { toggleDark, isDark } from "../../utils/common/helper";
import { setPreference, PREFERENCE_KEYS } from "../../utils/common/cache";
import {
  IonIcon,
  IonTitle,
  IonButton,
  IonHeader,
  IonSpinner,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";

const Header: React.FC<{
  onCreate?: () => void;
  onUpdate?: () => void;

  createMcq?: string;
  createFill?: string;
  createAnyone?: string;
  createCoding?: string;

  codeParserIcon?: boolean;
  createCourse?: string;
  createTopic?: string;
  logoutIcon?: boolean;
  backBtn?: string;

  loading?: boolean;
}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = new AuthState();

  return (
    <IonHeader>
      <IonToolbar>
        {props.backBtn && (
          <IonButtons slot="start" className="md:hidden">
            <IonBackButton defaultHref={props.backBtn}></IonBackButton>
          </IonButtons>
        )}
        <IonTitle>
          <strong className="text-yellow-500">x</strong>
          <strong className="cursor-pointer" onClick={(e) => history.push("/")}>
            CodeClazz CPanel
          </strong>
        </IonTitle>
        <IonButtons slot="end">
          {props.loading && <IonSpinner name="dots" />}
          {props.onCreate && <IonButton onClick={props.onCreate}>Create</IonButton>}
          {props.onUpdate && <IonButton onClick={props.onUpdate}>Update</IonButton>}
          {props.codeParserIcon && <IonButton href={`https://xcc-editor.surge.sh`} target="_blank">Code Parser</IonButton>}
          {props.createTopic && <IonButton routerDirection="none" routerLink={props.createTopic}>Create Topic</IonButton>}
          {props.createCourse && <IonButton routerDirection="none" routerLink={props.createCourse}>Create Course</IonButton>}
          {props.createMcq && <IonButton routerDirection="none" routerLink={props.createMcq}>+ Mcq</IonButton>}
          {props.createFill && <IonButton routerDirection="none" routerLink={props.createFill}>+ Fill</IonButton>}
          {props.createAnyone && <IonButton routerDirection="none" routerLink={props.createAnyone}>+ Anyone</IonButton>}
          {props.createCoding && <IonButton routerDirection="none" routerLink={props.createCoding}>+ Coding</IonButton>}
          {authState.validateUser() ? (
            <></>
          ) : (
            <>
              {/* <IonButton routerDirection="none" routerLink="/login">Login</IonButton> */}
              {/* <IonButton routerDirection="none" routerLink="/register">Register</IonButton> */}
            </>
          )}
          {<IonButton title="theme" onClick={e => { toggleDark(); setPreference(PREFERENCE_KEYS.DARK_MODE, `${isDark()}`); dispatch(setTheme({ theme: `${isDark()}` })) }}>
            <IonIcon slot="icon-only" icon={moonOutline} />
          </IonButton>}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
