import _ from "lodash";
import React, { useEffect } from "react";
import Header from "../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, getQuestionsTrending } from "../apis";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import { saveTrendingQuestions } from "../redux/reducers/questionState";
import { QuestionsResponse_ShowTrendingQuestionsList } from "@com.xcodeclazz/monolithic-common";

const Dashboard: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const trending_state: QuestionsResponse_ShowTrendingQuestionsList = useSelector((state: any) => state.questionState.trending);

  const getTrendingQuestions = () => getQuestionsTrending((res) => dispatch(saveTrendingQuestions(res.data)), errorToast);
  useEffect(() => { if (!trending_state) getTrendingQuestions() }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      return event.returnValue = 'Are you sure you want to leave?';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen className="ion-padding">
        <IonButton fill="solid" routerDirection="none" routerLink="/courses">Courses</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
